@font-face {
  font-family: "Aboreto";
  src: url("../fonts/Aboreto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Regular.ttf") format("truetype");
}

.navbar {
  background-color: rgb(14, 13, 23) !important;
}

.navbar-brand {
  font-family: "Aboreto", serif !important;
  font-size: 32px !important;
}

.nav-link {
  font-family: "Inter", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-size: 16px !important;
  padding: 0.5rem 1rem !important;
  margin-top: 0px !important;
  align-items: center;
}

btn {
  --bs-btn-padding-x: 2rem !important;
  --bs-btn-padding-y: 0.6rem !important;
  --bs-btn-font-family:  !important;
  --bs-btn-font-size: 1rem !important;
  --bs-btn-font-weight: 700 !important;
  --bs-btn-line-height: 1.6 !important;
  --bs-btn-color: #fff !important;
  --bs-btn-bg: transparent !important;
  --bs-btn-border-width: 1px !important;
  --bs-btn-border-color: transparent !important;
  --bs-btn-border-radius: 2rem !important;
  --bs-btn-hover-border-color: transparent !important;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075) !important;
  --bs-btn-disabled-opacity: 0.65 !important;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem
    rgba(var(--bs-btn-focus-shadow-rgb), 0.5) !important;
  display: inline-block !important;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x) !important;
  font-family: var(--bs-btn-font-family) !important;
  font-size: var(--bs-btn-font-size) !important;
  font-weight: var(--bs-btn-font-weight) !important;
  line-height: var(--bs-btn-line-height) !important;
  color: var(--bs-btn-color) !important;
  text-align: center !important;
  text-decoration: none !important;
  vertical-align: middle !important;
  cursor: pointer !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color) !important;
  border-radius: var(--bs-btn-border-radius) !important;
  background-color: var(--bs-btn-bg) !important;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out !important;
}
